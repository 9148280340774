<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0901 4.07992L8.57009 10.5999C7.80009 11.3699 7.80009 12.6299 8.57009 13.3999L15.0901 19.9199"
      stroke-miterlimit="10"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
